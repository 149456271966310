import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import "./styles/main.scss"

Vue.config.productionTip = false


const files = require.context('./', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

new Vue({
  render: h => h(App),
}).$mount('#app')
