<template>
  <div class="info">
    <img src="/images/info.svg" alt=""> <!-- ???????? // TODO for desktop! -->
    <div class="info_resp">
      <h2><span>Who we are?</span></h2>
      <p>
        <b>VANDOORWAY</b> is an Australian based and operated
        start-up business, based in Melbourne,Victoria.
        We are trying to create an ultimate solution that will
        connect local businesses and the community.
        Our ultimate goal is to build <b>one data base
        for all</b> local vans that offer mobile products 
        and services. At the moment, we are in the Research 
        & Developement stage and <b>we would love to invite
        all ice-cream van’s owners to participate in our project!</b>
      </p>
      <h2><span>What we do?</span></h2>
      <p>
        We are creating a mobile solution that will offer an easy access
        to your current location, products & services. We’ll infrom your local
        customers of your location during your work hours and once you on the
        spot & open for business. <b>We are just trying...to make it easier for everyone!</b>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info"
}
</script>