<template>
  <div class="footer">
    VANDOORWAY  2021
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>