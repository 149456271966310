<template>
  <div class="benefits">
    <div class="container">
      <div class="benefits__title">
        Benefits of partnering<br>
        up with <span>VAN</span>DOOR<span>WAY</span>
      </div>
      <div class="benefits__items">
        <div class="benefits__items__item">
          <div class="benefits__items__item__img">
            <img src="/images/benefits_1.svg" alt="">
          </div>
          <div class="benefits__items__item__header">
            Increase exposure <br>
            & brand awerness
          </div>
        </div>


        <div class="benefits__items__item">
          <div class="benefits__items__item__img">
            <img src="/images/benefits_2.svg" alt="">
          </div>
          <div class="benefits__items__item__header">
            Maximize your <br>
            anuual income
          </div>
        </div>

        <div class="benefits__items__item">
          <div class="benefits__items__item__img">
            <img src="/images/benefits_3.svg" alt="">
          </div>
          <div class="benefits__items__item__header">
            Easily connect with <br>
            your local customers
          </div>
        </div>
      </div>

      <h3>Would you like to be listed on our app?</h3>
      <h2>Scroll down.</h2>
      <img class="benefits__arrow" src="/images/arrow_red.svg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "Provide"
}
</script>