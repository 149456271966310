<template>
    <div class="join" id="join">
      <div class="join__text">
        <img src="/images/join_now_text.svg" alt="">
      </div>

      <div class="join__contact">
        <div class="join__contact__form">
          <h2>JOIN VANDOORWAY TODAY</h2>
          <h3>Leave us your details and we’ll contact you shortly!</h3>
          <input v-model="email" type="text" placeholder="Email">
          <input v-model="businessName" type="text" placeholder="Business Name">
          <input v-model="number" type="text" placeholder="Best Contact Number">
          <img @click="sendEmail" class="join__contact__form__button" src="/images/enquire.svg" alt="">
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "Join",
  data() {
    return {
      email: "",
      businessName: "",
      number: ""
    }
  },
  mounted() {

  },
  methods: {
    sendEmail() {
      var bodyFormData = new FormData();
      bodyFormData.append('email', this.email);
      bodyFormData.append("Business Name", this.businessName);
      bodyFormData.append('Best Contact Number', this.number);

      this.$axios({
        method: "post",
        url: "https://releeph.mikebreeze.ru/vandoorway_email/email.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
          .then(function () {
            //handle success
            alert("Your request sent successfully!")
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
    }
  }
}
</script>