<template>
  <div class="provide">

    <div class="container">

      <h2>What do you need to provide?</h2>
      <h3>In order to be listed on our app, you’ll be required to provide some essential infromation about your business.  </h3>

      <div class="provide__items">
        <div class="provide__items__item">
          <div class="provide__items__item__img">
            <img src="/images/provide_1.svg" alt="">
          </div>
          <div class="provide__items__item__header">
            Name + Logo <span>of your brand</span>
          </div>
          <div class="provide__items__item__description">
            Please share your business name & .png logo file, so we
            can upload it on our app!
          </div>
        </div>


        <div class="provide__items__item">
          <div class="provide__items__item__img">
            <img src="/images/provide_2.svg" alt="">
          </div>
          <div class="provide__items__item__header">
            Your Location <span>or set of locations for the day</span>
          </div>
          <div class="provide__items__item__description">
            Supply us with your daily itinerary,  as we need
            to know your exact location/s & hours,
            so your customers are there for you!
          </div>
        </div>

        <div class="provide__items__item">
          <div class="provide__items__item__img">
            <img src="/images/provide_3.svg" alt="">
          </div>
          <div class="provide__items__item__header">
            Prices & Photos <span>of your products</span>
          </div>
          <div class="provide__items__item__description">
            To make everything look fantabulous & consistent with our design guidelines,
            please provide us with your pricing, product descriptions & images.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Provide"
}
</script>