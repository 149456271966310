<template>
  <div id="app">
    <Header></Header>
    <Info></Info>
    <Benefits></Benefits>
    <Provide></Provide>
    <Join></Join>
    <Footer></Footer>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

