<template>

  <div class="hero">
    <div class="hero__header-title">
      <span class="hero__header-title_light">Welcome to</span> <br> VAN<span class="hero__header-title_red">DOOR</span>WAY!
    </div>

    <div class="hero__sign-up">
      <h2>Waiting for the app to roll out?</h2>
      <p>
        Be one of the first to try it - sign up for the beta.
      </p>
      <div class="hero__sign-up__input">
        <input v-model="email" type="text" placeholder="Your Email">
        <a href="#" @click="sendEmail">Sign Up</a>
      </div>
    </div>

    <div class="hero__join">
      <span>Van Owner?</span>
      <a href="#join">
        <img src="/images/join_now.svg" alt="">
      </a>
    </div>

    <div class="hero__arrow">
      <img src="/images/arrow_red.svg" alt="">
    </div>

    <div class="hero__join-message">
      Join <span>VANDOORWAY</span> <br> with your Mobile Van today!
    </div>
  </div>
</template>

<script>
export default  {
  name: "Header",
  data() {
    return {
      email:""
    }
  },
  methods: {
    sendEmail() {
      var bodyFormData = new FormData();
      bodyFormData.append('email', this.email);
      bodyFormData.append("Contact", this.email);
      bodyFormData.append('Action', "User sign up!");

      this.$axios({
        method: "post",
        url: "https://releeph.mikebreeze.ru/vandoorway_email/email.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
          .then(function () {
            //handle success
            alert("Your request sent successfully!")
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
    }
  }
}
</script>